.main-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.next-enter {
  transform: translateX(100%);
}
.next-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease-in-out;
}
.next-exit {
  transform: translateX(0);
}
.next-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}

.back-enter {
  transform: translateX(-100%);
}
.back-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease-in-out;
}
.back-exit {
  transform: translateX(0);
}
.back-exit-active {
  transform: translateX(100%);
  transition: transform 500ms ease-in-out;
}
